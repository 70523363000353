<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="register-wrap d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Регистрация
          </b-card-title>
          <!-- <b-card-text class="mb-2">
          </b-card-text> -->
          <html-content
            api="https://api.vestaorganic.ru/api/v1/htm/app/service/page/link/getBuyer"
          />
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Фамилия"
                label-for="xbu_LN"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Фамилия"
                  rules="required"
                >
                  <b-form-input
                    id="xbu_LN"
                    v-model="formData.xbu_LN"
                    :state="errors.length > 0 ? false:null"
                    name="xbu_LN"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- xbu_FN -->
              <b-form-group
                label="Имя"
                label-for="xbu_FN"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Имя"
                  rules="required"
                >
                  <b-form-input
                    id="xbu_FN"
                    v-model="formData.xbu_FN"
                    :state="errors.length > 0 ? false:null"
                    name="xbu_FN"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Отчество"
                label-for="xbu_MN"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Отчество"
                >
                  <b-form-input
                    id="xbu_MN"
                    v-model="formData.xbu_MN"
                    name="xbu_MN"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="E-mail"
                label-for="xbu_mail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="E-mail"
                >
                  <b-form-input
                    id="xbu_mail"
                    v-model="formData.xbu_mail"
                    name="xbu_mail"
                    placeholder="user@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Телефон"
                label-for="xbu_tel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Телефон"
                >
                  <b-form-input
                    id="xbu_tel"
                    v-model="formData.xbu_tel"
                    v-mask="'+7(###) ###-####'"
                    name="xbu_tel"
                    placeholder="+7(999) 999-9999"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group
                label="Дата рождения"
                label-for="xx_bithday"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Дата рождения"
                >
                  <date-picker
                    v-model="formData.xx_bithday"
                    class="w-100"
                    format="DD.MM.YYYY"
                    placeholder="дд.мм.гггг"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <b-form-group
                label="Город"
                label-for="xx_town"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Город"
                >
                  <b-form-input
                    id="xx_town"
                    v-model="formData.xx_town"
                    name="xx_town"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Телефон партнера, номер партнера или промокод"
                label-for="xx_ref"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Телефон партнера, номер партнера или промокод"
                >
                  <b-input-group>
                    <b-form-input
                      id="xx_ref"
                      v-model="formData.xx_ref"
                      :state="errors.length > 0 ? false:null"
                      name="xx_ref"
                    />
                    <b-input-group-append is-text>
                      <b-button
                        v-b-tooltip.hover.top="'Номер, который сообщает вам человек, рекомендующий данный сайт. Если не знаете – оставьте пустым.'"
                        variant="flat"
                        size="sm"
                        class="p-0"
                      >
                        ?
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Придумайте свой пароль"
                label-for="xx_pass"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Пароль"
                >
                  <b-input-group>
                    <b-form-input
                      id="xx_pass"
                      v-model="formData.xx_pass"
                      name="xx_pass"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Соглашение"
                  :rules="{ required: { allowFalse: false } }"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="formData.status"
                    :state="formData.status"
                    name="checkbox-1"
                  >
                    Я согласен с
                    <b-link
                      :href="server + '/confidenceBinar'"
                      target="blank"
                    >
                      политикой конфиденциальности и условиями
                    </b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                :disabled="loading"
                type="submit"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-spinner
                    v-if="loading"
                    small
                    class="mr-50"
                  />
                  <span>Зарегистрироваться</span>
                </div>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 mb-2">
            <span>Уже есть аккаунт? </span>
            <b-link :to="{ name:'login', query: { ...$route.query } }">
              <span>Войти</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Register v1 -->
    </b-row>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BCardTitle, BCardText, BForm,
  BRow, BCol, BImg,
  BButton, BFormInput, BFormGroup, BFormCheckbox,
  BSpinner,
  BInputGroup, BInputGroupAppend,
  VBTooltip,
} from 'bootstrap-vue'
import HtmlContent from '@core/components/html-content/HtmlContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { ref } from '@vue/composition-api'
import { getCookie, useRouter } from '@core/utils/utils'
// import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    // BSV
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BRow,
    BCol,
    BImg,
    HtmlContent,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require('@/assets/images/pages/vestabg.jpg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/vestabg.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup(_, { root }) {
    const server = 'https://lk.vestaorganic.ru'
    const registerForm = ref(null)
    const { router } = useRouter()

    const formData = ref({})
    const loading = ref(false)
    const xxBithday = ref('')
    const zbid = getCookie('vl_idB')
    formData.value.xx_ref = router.currentRoute.params.ref || router.currentRoute.params.pathMatch || router.currentRoute.query.bc || formData.value.xx_ref || zbid || ''
    if (router.currentRoute.params.pathMatch) {
      formData.value.id_spon = router.currentRoute.params.ref || router.currentRoute.params.pathMatch
    }
    const validationForm = () => {
      loading.value = true
      const zuid = getCookie('vl_idB')
      registerForm.value.validate().then(success => {
        if (success) {
          useJwt.register({
            ...formData.value,
            xx_source: 'partner',
            // xx_bithday: formData.value.xx_bithday && moment(formData.value.xx_bithday).format('MM.DD.YYYY'),
            zuid,
          }).then(response => {
            const { data } = response.data
            if (data) {
              const { userData } = data

              useJwt.setToken(data.accessToken)
              useJwt.setRefreshToken(data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              store.commit('app/SET_USER', userData)
              store.commit('app/SET_LOGGED_IN', true)
              loading.value = false

              window.location.replace('/')

              // if (userData.tmp) {
              //   window.location.replace(`/${userData.tmp}`)
              // } else {
              //   window.location.replace('/')
              // }

              // root.$swal({
              //   icon: 'success',
              //   html: data.descr,
              //   customClass: {
              //     confirmButton: 'btn btn-success',
              //   },
              // }).then(() => {
              //   router.push({
              //     name: 'verify-login',
              //     params: {
              //       id: data.id,
              //     },
              //   })
              // })
            }
          }).catch(error => {
            const { data } = error.response.data
            root.$swal({
              icon: 'error',
              html: data.msg,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
            loading.value = false
            registerForm.value.setErrors(error)
          })
        } else {
          loading.value = false
        }
      })
    }

    return {
      server,
      loading,
      registerForm,
      formData,
      validationForm,
      xxBithday,
      zbid,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
